<template>
  <MiscellaneousReport dialog-name="Reports" />
</template>
<script>
import MiscellaneousReport from '@/views/app/miscellaneous/Report'

export default {
  name: 'ReportWrapper',
  components: {
    MiscellaneousReport
  }
}
</script>
